<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { api } from '@/api'
import { useToastStore } from '@/stores'

const route = useRoute()
const { $toast } = useToastStore()

let orderId = route.query.orderId
let token = route.query.token
let payment_status = route.query.payment_status as string
let redirect_source = route.query.redirect_source as string
if (redirect_source && typeof redirect_source === 'string') {
  redirect_source = window.decodeURIComponent(redirect_source)
} else {
  redirect_source = window.location.origin
}

onMounted(async () => {

  if (orderId && payment_status === 'success') {
    requestPayStatus()
    payStatusPolling()
  } else {
    paymentRedirect('Payment failure')
  }
})

let loadingVisible = ref(true)
function paymentRedirect(text: string) {
  loadingVisible.value = false
  $toast(text)
  setTimeout(() => {
    window.location.replace(redirect_source)
  }, 1500)
}

let pollingTimer: any = null
let pollingCount = 0
async function requestPayStatus() {
  try {
    const { data } = await api.post(`/member/topup/verifyReceipt?orderNum=${orderId}&receipt=${token}`)
    if (data) {
      $toast('Payment success')
      paymentRedirect('Payment success')
      pollingTimer && clearInterval(pollingTimer)
    }
  } catch (error) {
    pollingTimer && clearInterval(pollingTimer)
    paymentRedirect('Payment failure')
  }
}
function payStatusPolling() {
  pollingTimer = setInterval(async () => {
    requestPayStatus()
    pollingCount++
    if (pollingCount > 15) {
      pollingTimer && clearInterval(pollingTimer)
      paymentRedirect('Payment failure')
    }
  }, 2000)
}
</script>

<template>
  <div>
    <div class="w-full h-screen bg-white" v-if="loadingVisible">
      <div class="pt-20vh flex justify-center">
        <BasicSpinner class="mx-auto" />
      </div>
      <p class="mt-8 font-bold text-center">
        <span>{{ 'Recharge please wait' }}</span>
      </p>
    </div>
  </div>
</template>

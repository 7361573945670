<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { $getToken } from '@/helpers'
const iframeUrl = ref(`https://xy-h5.17kcps.com/#/issue?token=${$getToken()}&platform=3`)
</script>

<template>
  <div class="bg-#fff">
    <div class="h-44px">
      <BasicNavbar opacity />
    </div>
    <iframe :src="iframeUrl" frameborder="0" class="w-full h-100vh"></iframe>
  </div>
</template>

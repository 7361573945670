<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'
import { useRouter } from 'vue-router/composables'
import { api } from '@/api'
import moment from 'moment'

const records = ref<any[]>([])
const page = ref(1)
const fetching = ref(false)
const ending = ref(false)
const error = ref<any>()
const pagination = computed(() => ({
  pageIndex: page.value,
  pageSize: 20,
}))

async function fetchFeed() {
  fetching.value = true
  try {
    const { data } = await api.get<any, GetMemberGetconsumerecordlistResponse>('/member/getConsumeRecordList', { params: { ...pagination.value } })
    records.value = [...records.value, ...data.records]
    if (page.value >= data.pages)
      ending.value = true
    else
      page.value += 1
  }
  catch (_error) {
    error.value = _error
  }
  finally {
    fetching.value = false
  }
}
const infiniteRef = ref<HTMLElement>()
useInfiniteScroll(infiniteRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    await fetchFeed()
  },
  { distance: 50 },
)

const router = useRouter()
function gotoChapter(item: any) {
  router.replace({
    name: 'chapter',
    params: {
      contentId: item.contentId,
      chapterId: item.chapterId,
    },
  })
}
</script>

<template>
  <div ref="infiniteRef" class="h-screen overflow-scroll bg-white">
    <BasicNavbar />

    <div v-if="!fetching && page === 1 && records.length === 0" class="mt-11 pt-15vh w-full text-center flex flex-col justify-center">
      <BizBlockEmpty title="No consumption record" />
    </div>
    <div v-else class="pt-44px px-5">
      <div v-for="(item, index) in records" :key="index">
        <div
          class="mt-5 flex items-center space-x-15px"
          @click="gotoChapter(item)"
        >
          <div class="relative">
            <img class="w-18 h-25 rounded-3px" :src="item.contentCoverUrl">
          </div>
          <div class="flex-1">
            <template v-if="item.chapterName !== '0'">
              <p class="text-15px font-bold line-clamp-1">
                {{ item.chapterName }}
              </p>
              <p class="text-13px mt-2 text-#9296A0 line-clamp-1">
                <span>{{ item.contentName }}</span>
              </p>
            </template>
            <template v-else>
              <p class="text-15px font-bold line-clamp-2">
                {{ item.contentName }}
              </p>
            </template>
            <p class="flex justify-between text-13px mt-2 text-#9296A0">
              <span>{{ moment(item.subscriptionTime).format('LL') }}</span>
              <span>-{{ item.coinNum }} coins</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="ending && records.length" class="py-15px text-13px text-#999 text-center">
      No more ~
    </div>
    <div v-else-if="fetching" class="py-15px flex items-center justify-center space-x-4 text-13px text-#999">
      <div class="animate-spin">
        <i-icon-park-outline-loading />
      </div>
      <p>Loading...</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { api } from '@/api'
import { useLoadingStore, useToastStore, useUserStore } from '@/stores'
import { login } from '@/fb'
import { $formatErrorMessage } from '@/helpers'

// import { loadStripe } from "@stripe/stripe-js"

const props = withDefaults(
  defineProps<{
    type?: number
    contentId?: string
    contentChapterId?: string
  }>(),
  {
    type: 2,
    contentId: '',
    contentChapterId: '',
  },
)
const { $showLoading, $hideLoading } = useLoadingStore()
const userStore = useUserStore()
const { $toast } = useToastStore()
// let stripe = null

const products = ref<any>([])
onMounted(async () => {
  try {
    const params: any = {
      type: props.type,
    }
    if (props.type === 1) {
      params.contentId = props.contentId
      params.contentChapterId = props.contentChapterId
    }
    const { data } = await api.get<any>('/member/topup/product/list', {
      params,
    })
    products.value = data
  }
  catch (error) {
    $toast($formatErrorMessage(error))
  }
  // stripe = await loadStripe(
  //   "pk_test_51Q370eAuL8dzvcxnpfIfMUC6AuTKUAMLcRYL654gLhYRjne36iKKxE3AnvyjrRnOdTF0hENLEcunHM0w7EaajObB00pjkPoieV"
  // )
})

const thirdAuthVisible = ref(false)
const templateCache = ref<any>(null)
async function onClickPayTemplate(item: any) {
  templateCache.value = item

  // // 未登录
  // if (!userStore.$state.thirdPartId) {
  //   thirdAuthVisible.value = true
  //   return
  // }

  $showLoading()

  const url = `${window.location.origin}/paying?redirect_source=${encodeURIComponent(window.location.href)}`
  const params = {
    payConfigId: item.payConfigId,
    productId: item.productId,
    contentId: props.contentId,
    contentChapterId: props.contentChapterId,
    memberCurrencyCode: 'usd',
    payType: 6,
    type: 2,
    successUrl: `${url}&payment_status=success`,
    cancelUrl: `${url}&payment_status=cancel`,
  }

  try {
    const { data } = await api.post<any>('/member/topup/createPaypalPaymentIntent', params)
    window.location.replace(data)
  }
  catch (error) {
    $toast($formatErrorMessage(error))
    $hideLoading()
  }
}

function onThirdAuth() {
  thirdAuthVisible.value = false
  login().then(async (res: any) => {
    try {
      const { data } = await api.post<any>('/member/bind/facebook', {
        thirdPartId: res.id,
        nickName: res.name,
        email: res.email,
        headImage: res.picture?.data?.url ?? '',
      })
      localStorage.setItem('token', data.token)
      await userStore.init({ force: true })
      onClickPayTemplate(templateCache.value)
    }
    catch (error) {
      $toast($formatErrorMessage(error))
    }
  }).catch((error) => {
    $toast(error.msg || 'Network error')
  })
}
</script>

<template>
  <div>
    <div class="pay-template pt-2px pb-12px">
      <div v-for="(item, index) in products" :key="index" class="pay-template-info flex items-center space-x-15px mt-10px mx-10px px-16px py-16px b-rd-2 bg-#F3F5FA border border-color-#F3F5FA" @click="onClickPayTemplate(item)">
        <div>
          <img class="w-24px h-24px" src="@/assets/welfare/welfare-gold.png">
        </div>
        <div class="flex-1">
          <div>
            <strong class="text-18px">{{ item.coinsBuy }}</strong>
            <span class="ml-5px text-14px text-#65686F">coins</span>
          </div>
          <div class="flex items-center">
            <span class="text-14px text-#9875FB">+{{ item.coinsBonus }} coins</span>
            <i v-if="item.marketingText" class="inline-block ml-10px px-9px bg-#F74984 b-rd-12px text-#fff text-10px font-not-italic">{{ item.marketingText }}</i>
          </div>
        </div>
        <div class="text-right">
          <strong class="text-18px">$ {{ item.price }}</strong>
          <p v-if="item.cornerTip" class="text-12px text-#65686F">
            +{{ parseFloat((item.coinsBonus / item.coinsBuy * 100).toFixed(2)) }}% coins
          </p>
        </div>
      </div>
    </div>
    <BasicModal v-model="thirdAuthVisible">
      <div class="p-5">
        <a class="flex items-center justify-center h-44px b-rd-3px bg-#4c69ba text-#fff" @click="onThirdAuth">
          <img class="_55at img" src="https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/FehCVG1ix9z.png" alt="" width="18" height="18">
          <span class="ml-6px">Login with Facebook</span>
        </a>
      </div>
    </BasicModal>
  </div>
</template>

<style lang="scss" scoped>
.pay-template-info:hover {
  border: 1px solid #9875FB;
  background-color: #F3EFFF;
}
</style>

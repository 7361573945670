<!-- Popup: TOC(Table of Contents) -->

<script lang="ts" setup>
import { inject, nextTick, ref, watch } from 'vue'
import { useScroll } from '@vueuse/core'
import { api } from '@/api'
import { useLoadingStore } from '@/stores'
import { CHAPTER_LOCK } from '@/constants'

const props = withDefaults(
  defineProps<{
    value: boolean
    book: any
    contentId: string
    chapterId?: string
    checkPassGotoChapter?: (index: number) => boolean
  }>(), {
    checkPassGotoChapter: () => true,
  },
)

defineEmits<{
  (event: 'input', value: boolean): void
}>()

const theme = inject<{ background: string } | null>('theme', { background: 'white' })

const { $showLoading, $hideLoading } = useLoadingStore()

const chapterList = ref<GetContentGetcontentchapterbooklistResponse['data']['records']>([])
const chapterScrollRef = ref()
const { y: chapterScrollY } = useScroll(chapterScrollRef, { behavior: 'smooth' })
const chooseChapterOpitons = ref(false)
const chapterOptions = ref<any[]>([])
const chapterTotal = ref<Number>(0)
const pagination = ref({
  pageIndex: 1,
  pageSize: 100,
})
async function fetchChapterList() {
  $showLoading()
  try {
    const { data: chapterListData } = await api.get<any>('/content/getContentChapterBookList', { params: { contentId: props.contentId, ...pagination.value } })
    chapterList.value = chapterListData.records
    chapterTotal.value = chapterListData.total
    const page = chapterListData.pages
    // Note: 章节分页 options
    chapterOptions.value = Array.from({ length: page })
      .map((_, index) => ({
        label: `${(index * pagination.value.pageSize + 1)} - ${(index + 1) * pagination.value.pageSize}`,
        value: index + 1,
      }))
    // Note: auto scroll to current chapterId
    if (props.chapterId) {
      const itemHeight = 57
      const index = chapterList.value.findIndex(item => item.id === props.chapterId)
      nextTick(() => {
        if (index > -1)
          chapterScrollY.value = index * itemHeight
      })
    }
  }
  finally {
    $hideLoading()
  }
}
async function onOpen() {
  await fetchChapterList()
}
watch(() => pagination.value.pageIndex, async () => {
  await fetchChapterList()
  chooseChapterOpitons.value = false
})
</script>

<template>
  <!-- Popup: 查看章节 -->
  <BasicPopup :value="value" @input="value => $emit('input', value)" @open="onOpen">
    <div v-if="book" class="h-90vh overflow-scroll rounded-t" :style="`background: ${theme ? theme.background : ''}`">
      <div class="pt-5 pb-13px px-5 bg-#0000000A">
        <div class="flex space-x-10px">
          <img class="w-55px h-77px rounded-3px" :src="book.contentCoverUrl">
          <div class="flex flex-col justify-center">
            <p class="text-17px font-bold line-clamp-2">
              {{ book.contentName }}
            </p>
            <p class="mt-2 text-13px text-#666">
                {{ chapterTotal }} episodes
            </p>
          </div>
        </div>
        <!-- <div class="mt-5 flex items-center space-x-10px text-13px">
          <span class="flex-1 truncate text-#666">
            {{ book.chapterName }}
          </span>
          <div
            class="flex items-center space-x-1" :class="{ 'text-primary': chooseChapterOpitons }"
            @click="chooseChapterOpitons = !chooseChapterOpitons"
          >
            <span>{{ chapterOptions[pagination.pageIndex - 1]?.label }}</span>
            <i-carbon-caret-down class="transition-all duration-500" :class="{ 'rotate-180': chooseChapterOpitons }" />
          </div>
        </div> -->
      </div>
      <ul v-show="chooseChapterOpitons" class="px-5 h-[calc(90vh-150px)] overflow-scroll">
        <li
          v-for="item in chapterOptions" :key="item.value"
          class="h-57px lh-57px flex items-center justify-between border-b border-#0000001A"
          :class="{ 'text-primary font-bold': pagination.pageIndex === item.value }"
          @click="pagination.pageIndex = item.value"
        >
          <span>{{ item.label }}</span>
          <i-assets-icon-check v-if="pagination.pageIndex === item.value" class="w-17px" />
        </li>
      </ul>
      <ul v-show="!chooseChapterOpitons" ref="chapterScrollRef" class="px-5 h-[calc(90vh-150px)] overflow-scroll">
        <router-link
          v-for="item in chapterList" :key="item.id"
          v-slot="{ navigate }" custom
          :to="{ name: 'chapter', params: { contentId, chapterId: item.id }, query: { skipPosition: '1' } }" replace
        >
          <li
            class="h-57px lh-57px flex items-center justify-between border-b border-#0000001A"
            :class="{ 'text-primary font-bold': chapterId === item.id }"
            @click="$event => checkPassGotoChapter(item.sequenceNo) && navigate($event)"
          >
            <span class="flex-1 text-truncate">{{ item.chapterName }}</span>
            <i-assets-icon-lock v-if="item.lockStatus === CHAPTER_LOCK.LOCK" class="w-17px" />
          </li>
        </router-link>
      </ul>
    </div>
  </BasicPopup>
</template>

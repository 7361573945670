import { type RouteConfig } from 'vue-router'
import Debug from '@/pages/debug.vue'
import Home from '@/pages/home.vue'
import Authorize from '@/pages/authorize.vue'
import Search from '@/pages/search.vue'
import History from '@/pages/history.vue'
import Category from '@/pages/category.vue'
import Member from '@/pages/member.vue'
import ContactUs from '@/pages/contact-us.vue'
import Setting from '@/pages/setting.vue'
import Pay from '@/pages/pay.vue'
import Paying from '@/pages/paying.vue'
import Vip from '@/pages/vip.vue'
import Consume from '@/pages/consume.vue'
import Recharge from '@/pages/recharge.vue'
import Preview from '@/pages/preview.vue'
import Book from '@/pages/book.vue'
import BookFinished from '@/pages/book-finished.vue'
import Chapter from '@/pages/chapter.vue'
import Welfare from '@/pages/welfare.vue'
import Activity from '@/pages/activity.vue'
import Collection from '@/pages/collection.vue'
import Go from '@/pages/go.vue'
import CancelSubscribe from '@/pages/cancel-subscribe.vue'
import Error from '@/pages/error.vue'
import NotFound from '@/pages/404.vue'
import Complaint from '@/pages/complaint.vue'

const routes: Array<RouteConfig> = [
  { path: '/debug', name: 'debug', component: Debug, meta: { title: 'DEBUG', requiresAuth: false } },
  { path: '/', name: 'home', component: Home, meta: { title: 'Novel' }, props: true },
  { path: '/search', name: 'search', component: Search, meta: { title: 'Search' } },
  { path: '/history', name: 'history', component: History, meta: { title: '最近阅读' } },
  { path: '/category/:channel?', name: 'category', component: Category, meta: { title: '分类' }, props: true },
  { path: '/member', name: 'member', component: Member, meta: { title: 'Profile' } },
  { path: '/contact-us', name: 'contact-us', component: ContactUs, meta: { title: 'Contact us' } },
  { path: '/setting', name: 'setting', component: Setting, meta: { title: 'Setting' } },
  { path: '/recharge', name: 'recharge', component: Recharge, meta: { title: 'Transaction History' } },
  { path: '/consume', name: 'consume', component: Consume, meta: { title: 'Chapters UnLocked' } },
  { path: '/pay', name: 'pay', component: Pay, meta: { title: 'Store' } },
  { path: '/book-finished', name: 'book-finished', component: BookFinished, meta: { title: '没有续看的书' } },
  { path: '/content/preview', name: 'preview', component: Preview, meta: { title: 'preview' } },
  { path: '/content/:contentId', name: 'book', component: Book, meta: { title: 'Detail' }, props: true },
  { path: '/content/:contentId/:chapterId', name: 'chapter', component: Chapter, meta: { title: 'Chapter Detail' }, props: true },
  { path: '/welfare', name: 'welfare', component: Welfare, meta: { title: 'Rewards' } },
  { path: '/activity/:activityId', name: 'activity', component: Activity, meta: { title: '活动' }, props: true },
  { path: '/collection/:columnId', name: 'collection', component: Collection, meta: { title: '专栏' }, props: true },
  { path: '/authorize', name: 'authorize', component: Authorize, meta: { title: '授权中' }, props: route => ({ redirect: route.query.redirect, next: route.query.next, code: route.query.code }) },
  { path: '/paying', name: 'paying', component: Paying, meta: { title: 'Payment' }, props: route => ({ code: route.query.code, productId: route.query.productId }) },
  { path: '/go', name: 'go', component: Go, meta: { title: '跳转中' }, props: route => ({ type: route.query.type }) },
  { path: '/vip', name: 'vip', component: Vip, meta: { title: '开通会员' } },
  { path: '/cancel-subscribe', name: 'cancelSubscribe', component: CancelSubscribe, meta: { title: '取消订阅' } },
  { path: '/complaint', name: 'complaint', component: Complaint, meta: { title: '投诉' } },
  { path: '/500', name: 'error', component: Error, meta: { title: '服务器错误', requiresAuth: false }, props: route => ({ level: route.query.level }) },
  { path: '*', name: 'notFound', alias: '/404', component: NotFound, meta: { title: '404', requiresAuth: false } },
]

export default routes

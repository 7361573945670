<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useEventBus, useStorage } from '@vueuse/core'
import { isPast } from 'date-fns'
import { useUserStore } from '@/stores'
import { STORAGE_RECHARGE_INTERCEPTION } from '@/constants'
import router from '@/router'

const user = useUserStore()
const route = useRoute()
const products = ref<any>([])

// 充值拦截
const rechargeInterception = useStorage<Record<string, any>>(STORAGE_RECHARGE_INTERCEPTION, { _exist: false })
const eventBusRechargeInterception = useEventBus<string>('modal:rechargeInterception')

const canceled = ref(false)
const skipRechargeInterception = ref(false)

const Stripe = null

onMounted(async () => {
  if (route.query.skipRechargeInterception)
    skipRechargeInterception.value = true

  // Note: 如果是支付取消返回的页面
  if (route.query.cancel) {
    canceled.value = true

    if (!skipRechargeInterception.value && canceled.value)
      eventBusRechargeInterception.emit('open')

    skipRechargeInterception.value = true

    router.replace({
      query: {
        ...route.query,
        cancel: undefined,
        skipRechargeInterception: undefined,
      },
    })
  }

  // window.collectEvent('elementShow', {
  //   type: '充值页',
  //   page: '充值页',
  // })
  // Note: remove when expired
  if (isPast(rechargeInterception.value?.expiredAt))
    rechargeInterception.value = { _exist: false }
})
onUnmounted(() => {
  if (!skipRechargeInterception.value)
    eventBusRechargeInterception.emit('open')
})
</script>

<template>
  <div>
    <BasicNavbar />

    <!-- Cover -->
    <div class="pay-cover mt-44px mb-24px mx-10px bg-#fff b-rd-2 box-shadow">
      <div class="pt-18px px-26px">
        <p class="flex items-center space-x-5px">
          <span class="text-18px text-#9875FB font-bold">{{ user.$state.coinBalance }}</span>
          <span class="relative text-12px text-#666">coins</span>
        </p>
      </div>
      <BizPayTemplate />
      <div class="pb-20px px-16px text-10px text-#9296A0">
        <p class="text-12px mt-6px">
          Tips:
        </p>
        <p class="mt-6px">
          1. Coins and VIP are virtual items and cannot be refunded. They can only be used within this app.
        </p>
        <p class="mt-6px">
          2. Reward coins are time-sensitive. Please use them within the 7-day validity period, as they will be deducted first when unlocking content.
        </p>
        <p class="mt-6px">
          3. After purchasing VIP, you can read all the short stories in the VIP section during the validity period.
        </p>
        <p class="mt-6px">
          4. For other questions, please contact us via Profile > Customer Service.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pay-cover {
  min-height: calc(100vh - 68px);
}
</style>

export function getLoginStatus() {
  return new Promise((resolve, reject) => {
    try {
      // @ts-ignore
      FB.getLoginStatus(function(res) {
        resolve(res)
      })
    } catch (error) {
      reject(error)
    }
  })
}

export function login() {
  return new Promise((resolve, reject) => {
    try {
      // @ts-ignore
      FB.login(function(res) {
        if (res.authResponse) {
          // @ts-ignore
          // 另一种方式获取头像 picture.width(100).height(100)
          FB.api('/me', {fields: 'id,name,email,picture.type(large)'}, function(info) {
            resolve(info)
          })
        } else { 
          reject('login exception')
        }
      });
    } catch (error) {
      reject(error)
    }
  })
}

export function logout() {
  return new Promise((resolve, reject) => {
    try {
      // @ts-ignore
      FB.getLoginStatus(function(res) {
        if (res.status === 'connected') {
          // @ts-ignore
          FB.logout(function(info) {
            resolve(info)
          })
        }
      })
    } catch (error) {
      reject(error)
    }
  })
}

// src/hooks/useTracking.js

// import { useRouter } from 'vue-router/composables'
import { $getCookie, $getQueryParam } from '@/helpers'
import { useEffectiveSubmitTrackStore } from '@/stores'

export default function useFacebookTrack() {
  const { $attribution, $setParams } = useEffectiveSubmitTrackStore()

  const _fbp = $getCookie('_fbp') || undefined
  const _fbc = $getCookie('_fbc') || undefined
  const contentId = $getQueryParam('contentId') || ''

  const collectTrackParams = () => {
    const params = {
      _fbp,
      _fbc,
      attributeType: 'web',
      url: window.location.href,
      ua: navigator.userAgent,

      pId: $getQueryParam('pId') || '',
      contentId: $getQueryParam('contentId') || '',
      initIndex: $getQueryParam('initIndex') || '',
      utm_campaign_id: $getQueryParam('utm_campaign_id') || '',
      utm_campaign: $getQueryParam('utm_campaign') || '',
      utm_adset: $getQueryParam('utm_adset') || '',
      utm_adset_id: $getQueryParam('utm_adset_id') || '',
      utm_ad_name: $getQueryParam('utm_ad_name') || '',
      utm_ad_id: $getQueryParam('utm_ad_id') || '',
      utm_source: $getQueryParam('utm_source') || '',
      utm_medium: $getQueryParam('utm_medium') || '',
    }

    $attribution()
    $setParams(params)
  }

  return {
    async init() {
      const isFromFB = _fbp && _fbc && $getQueryParam('fbclid') && contentId
      if (isFromFB)
        collectTrackParams()
    },
  }
}

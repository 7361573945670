<script lang="ts" setup>
import queryString from 'query-string'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useTitle } from '@vueuse/core'
import { useConfigStore, useLoadingStore, useQueryStore, useToastStore, useTrackValueStore, useUserStore } from '@/stores'
import { api } from '@/api'
import { isDefined } from '@/utils'
import { ACTIVITY_STATUS, ACTIVITY_TYPE, PAY_LOCATION_PAGE, PRODUCT_TYPE, isIOS } from '@/constants'
import { generateOAuthUrl } from '@/wechat'
import { track } from '@/track'

const props = defineProps<{
  activityId: string
}>()

const route = useRoute()
const title = useTitle('Loading...')
// const navTitle = computed(() => title)

const { $toast } = useToastStore()
const { $showLoading, $hideLoading } = useLoadingStore()

const skeleton = ref(false)
const activity = ref<GetActivityGetactivityjoininfoResponse['data']>()
async function fetchActivity() {
  skeleton.value = true
  try {
    const { data } = await api.get<any, GetActivityGetactivityjoininfoResponse>('/activity/getActivityJoinInfo', { params: { activityId: props.activityId } })
    activity.value = data
    title.value = data.title || data.name
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || '获取活动失败')
  }
  finally {
    skeleton.value = false
  }
}
async function fetchActivityByOperation() {
  skeleton.value = true
  try {
    const { data } = await api.get<any, GetActivityGetactivityjoininfoResponse>('/activity/getRechargeActivityInfo', { params: { activityId: props.activityId } })
    // Note: 对齐一些参数
    title.value = data.activityName
    activity.value = {
      ...data,
      id: data.id || data.activityId,
      name: data.activityName,
      title: data.activityName,
      templateInfo: {
        bannerImgUrl: data.bannerUrl,
        contentText: data.kindReminder,
        name: data.activityName,
      },
    }
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || '获取活动失败')
  }
  finally {
    skeleton.value = false
  }
}
onMounted(() => {
  if (route.query.type === 'operational') {
    fetchActivityByOperation()
    return
  }
  fetchActivity()
})

const queryStore = useQueryStore()
const trackValueStore = useTrackValueStore()
const configStore = useConfigStore()
const userStore = useUserStore()

async function onTrigger() {
  $showLoading()
  try {
    // Note: 直接领取奖励
    if (activity.value!.activityType === ACTIVITY_TYPE.GIFT_COIN) {
      const { msg, data: success } = await api.get<any, GetActivityGetrewardResponse>('/activity/getReward', { params: { activityId: props.activityId, joinId: activity.value!.id } })
      if (success) {
        $toast('领取成功')
        track('activityReceived', {
          sourceId: route.query.sourceId ?? '',
          activityId: props.activityId,
          activityType: activity.value!.activityType,
        })
      }
      else {
        $toast(msg || '领取失败')
      }
      return
    }

    // Check
    if (activity.value!.activityType === ACTIVITY_TYPE.RECHARGE) {
      const { msg, data: passed } = await api.get<any, GetActivityCheckiscanpayResponse>('/activity/checkIsCanPay', { params: { activityId: props.activityId, joinId: activity.value!.id } })
      if (!passed) {
        $toast(msg || '您已经参加过该活动了')
        return
      }
    }

    // Note: 跳转充值页面
    // 商品类型 1 金币充值 2 vip充值
    const { productId, productType } = activity.value!
    await configStore.init({ force: true })
    const payDomain = configStore.$state.payDomain
    const appId = configStore.$state.payAppId
    if (!payDomain || !appId) {
      $toast('支付配置错误，请联系客服')
      return
    }
    const oAuthUrl = generateOAuthUrl({
      appId,
      redirect: `${payDomain}/paying?${queryString.stringify({
          redirect: window.encodeURIComponent(window.location.href),
          callbackUrl: window.encodeURIComponent(window.location.href),
          productId,
          appId: queryStore.$state.max_app_id,
          promotionId: queryStore.$state.max_pid,
          appVersion: __APP_VERSION__,
          memberId: userStore.$state.memberId,
          OS: isIOS ? 2 : 1, // 1: Android, 2: iOS, 3: Windows,
          paySid: queryStore.$state.max_sid,
          payLocation: PAY_LOCATION_PAGE.ACTIVITY,
          activityId: props.activityId,
          rechargeOaaId: route.query.sourceId ?? '', // 资源位 ID
          ...queryStore.$state,
          ...trackValueStore.$state,
        })}`,
    })
    window.location.replace(oAuthUrl)
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || '领取失败')
  }
  finally {
    $hideLoading()
  }
}
</script>

<template>
  <div>
    <BasicNavbar class="text-white" />
    <div v-if="skeleton">
      <div class="pt-20vh flex justify-center">
        <BasicSpinner class="mx-auto" />
      </div>
      <p class="mt-4 text-center font-bold">
        Loading...
      </p>
    </div>
    <div v-else-if="activity" class="w-full min-h-screen relative bg-#FFC147">
      <BizTrackShow
        id="activity_page_view" :name="activity.name" type="page"
        :extra="{
          source_id: route.query.sourceId ?? '',
          activity_id: activity.activityId,
          activity_name: activity.name,
          product_id: activity.productId,
          product_coins_buy: activity.coinsBuy,
          product_coins_bonus: activity.coinsBonus,
          template_name: activity.templateInfo?.name ?? '',
        }"
      >
        <img v-if="activity.templateInfo?.bannerImgUrl" :src="activity.templateInfo.bannerImgUrl" class="w-full min-h-30" alt="活动:头图">
        <div class="mt-4 mx-a w-335px h-337px relative">
          <img class="w-full" src="@/assets/cover-activity.png">
          <div v-if="activity.cornerTip" class="w-145px absolute left-26px top-40px text-16px text-#F53B27 flex h-37px">
            <img src="@/assets/activity-corner-left.png">
            <p class="text-truncate lh-37px relative corner-center">
              {{ activity.cornerTip }}
            </p>
            <img src="@/assets/activity-corner-right.png">
          </div>
          <div class="w-full absolute top-85px text-center font-bold">
            <template v-if="activity.productType === PRODUCT_TYPE.COIN">
              <p class="text-#FF002C text-20px font-bold">
                <span class="text-45px">{{ activity.coinsBuy + activity.coinsBonus }}</span>
                coins
              </p>
              <p v-if="activity.activityType === ACTIVITY_TYPE.RECHARGE" class="text-center">
                <span class="text-#950000 text-25px">￥{{ activity.price / 100 }}</span>
                <span class="relative -top-1px ml-2 text-#979797 text-14px line-through font-normal">{{ activity.footTip.split('#')?.[0] }}</span>
              </p>
              <p v-if="activity.activityType === ACTIVITY_TYPE.OPERATIONAL" class="text-center">
                <span class="text-#950000 text-25px">￥{{ activity.price / 100 }}</span>
                <span class="relative -top-1px ml-2 text-#979797 text-14px line-through font-normal">原价￥{{ Math.round(activity.price / 100 * 1.5) }}</span>
              </p>
            </template>
            <template v-else>
              <div class="h-125px flex flex-col items-center justify-center">
                <div v-if="activity.activityType === ACTIVITY_TYPE.OPERATIONAL" class="text-#FF002C font-bold text-30px lh-1.4em px-18 text-center break-words">
                  <p>
                    <span class="text-10">{{ activity.daysSubscribe }}</span>
                    <span class="ml-1">天VIP</span>
                  </p>
                </div>
                <p v-else class="text-#FF002C font-bold text-30px lh-1.3em px-18 text-center break-words">
                  <span>{{ activity.description }}</span>
                </p>
                <p v-if="activity.activityType === ACTIVITY_TYPE.RECHARGE" class="text-center">
                  <span class="text-#950000 text-25px">￥{{ activity.price / 100 }}</span>
                  <span class="relative -top-1px ml-2 text-#979797 text-14px line-through font-normal">{{ activity.footTip.split('#')?.[0] }}</span>
                </p>
                <p v-else-if="activity.activityType === ACTIVITY_TYPE.OPERATIONAL" class="text-center">
                  <span class="text-#950000 text-25px">￥{{ activity.price / 100 }}</span>
                  <span class="relative -top-1px ml-2 text-#979797 text-14px line-through font-normal">原价￥{{ Math.round(activity.price / 100 * 1.5) }}</span>
                </p>
              </div>
            </template>
          </div>
          <div v-if="activity.footTip" class="w-175px absolute bottom-18 left-1/2 -translate-x-1/2">
            <template v-if="activity.activityType === ACTIVITY_TYPE.OPERATIONAL">
              <img src="@/assets/bubble-activity.png">
              <p class="w-full absolute top-0 px-15px pt-8px text-center text-white text-truncate font-bold">
                {{ activity.footTip }}
              </p>
            </template>
            <template v-else>
              <img v-if="activity.footTip.split('#')?.[1]" src="@/assets/bubble-activity.png">
              <p v-if="activity.footTip.split('#')?.[1]" class="w-full absolute top-0 px-15px pt-8px text-center text-white text-truncate font-bold">
                {{ activity.footTip.split('#')?.[1] }}
              </p>
            </template>
          </div>
          <BizTrackClick name="领取" type="button" :extra="{ activityId }">
            <div class="w-50 h-16 absolute left-1/2 bottom-10px -translate-x-1/2 z-10" @click="onTrigger">
              <div class="activity-button">
                <p class="w-full lh-16 text-center text-#950000 text-23px text-truncate font-bold">
                  {{ activity.buttonText || "立即领取" }}
                </p>
              </div>
            </div>
          </BizTrackClick>
        </div>

        <p v-if="activity.feShowPeriodState" class="mt-5 py-2 text-center">
          Period of validity: {{ activity.startDate.split(' ')[0] }} - {{ activity.endDate.split(' ')[0] }}
        </p>

        <div class="mt-8 mx-a w-335px">
          <p class="text-15px font-bold text-#000000B2">
            Tips
          </p>
          <div class="py-10px text-14px text-#00000080">
            <p v-for="(content, index) in activity.templateInfo?.contentText.split(/\n/)" :key="index">
              {{ content }}
            </p>
          </div>
        </div>
      </BizTrackShow>

      <div
        v-if="isDefined(activity.activityStatus) && activity.activityStatus !== ACTIVITY_STATUS.NORMAL"
        class="fixed inset-0 bg-#000000B2 flex items-center justify-center text-white z-10"
      >
        <p v-if="activity.activityStatus === ACTIVITY_STATUS.NOT_START">
          Activity not started
        </p>
        <p v-if="activity.activityStatus === ACTIVITY_STATUS.PAUSE">
          Activity suspended
        </p>
        <p v-if="activity.activityStatus === ACTIVITY_STATUS.FINISHED">
          Activity has ended
        </p>
        <p v-if="activity.activityStatus === ACTIVITY_STATUS.OFFLINE">
          The event has been taken down
        </p>
      </div>
    </div>
    <div v-else>
      <BizBlockEmpty title="Activity not present" />
    </div>
  </div>
</template>

<style scoped>
.corner-center {
  background: url('@/assets/activity-corner-center.png');
  background-size: 100% 100%;
}
.activity-button {
  background: url('@/assets/activity-button.png');
  background-size: 100% 100%;
  animation: scale 0.6s 0.2s infinite;
}
@keyframes scale {
  0%, 25%, 50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useInfiniteScroll, useTitle } from '@vueuse/core'
import { api } from '@/api'
import { diffSeconds } from '@/utils'
import { COLUMN_TYPE } from '@/constants'

const props = defineProps<{
  columnId: string
}>()

const title = useTitle('专栏')
const hasCountdownCover = ref(false)
const countdown = ref(0)
const collections = ref<any>([])
const page = ref(1)
const fetching = ref(false)
const ending = ref(false)
const error = ref<any>()
const pagination = computed(() => ({
  pageIndex: page.value,
  pageSize: 20,
}))
async function fetchCollection() {
  fetching.value = true
  try {
    const { data } = await api.get<any, GetContentGetcolumncontentlistResponse>('/content/getColumnContentList', { params: { columnId: props.columnId, ...pagination.value } })
    hasCountdownCover.value = data.columnType === COLUMN_TYPE.COL_3_FREE
    countdown.value = diffSeconds(Date.now(), data.effectiveEndTime)
    collections.value = [...collections.value, ...data.resourceList.records]
    title.value = data.showName
    if (page.value >= data.resourceList.pages)
      ending.value = true
    else
      page.value += 1
  }
  catch (_error) {
    error.value = _error
  }
  finally {
    fetching.value = false
  }
}
const infiniteRef = ref<HTMLElement>()
useInfiniteScroll(infiniteRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    await fetchCollection()
  },
  { distance: 50 },
)
</script>

<template>
  <div ref="infiniteRef" class="h-screen bg-white overflow-scroll">
    <BasicNavbar />

    <div v-if="hasCountdownCover" class="cover h-185px px-10 pt-10">
      <BasicCountdown :seconds="countdown" theme="transparent" />
    </div>

    <div :class="hasCountdownCover ? '-mt-22 mx-15px px-15px py-5 bg-white rounded-5px' : 'mt-50px px-5'">
      <router-link
        v-for="(item, index) in collections" :key="index"
        :to="{ name: 'book', params: { contentId: item.id } }" replace
        class="mb-15px flex items-center space-x-15px"
      >
        <img class="w-18 h-25 rounded" :src="item.contentCoverUrl" alt="图片:书封">
        <div class="flex-1">
          <p class="text-15px font-bold line-clamp-1">
            {{ item.contentName }}
          </p>
          <p class="text-13px mt-10px text-#666 line-clamp-2">
            {{ item.introduce }}
          </p>
          <p v-if="hasCountdownCover" class="mt-10px flex space-x-10px text-13px">
            <span class="text-#FF7D00">免费</span>
            <span class="line-through text-#999">{{ item.priceAmount ?? 0 }}元/千字</span>
          </p>
          <div v-else class="mt-10px flex justify-between text-13px text-#999">
            <p>
              <span>{{ item.finishStatus ? 'Completed' : 'Ongoing' }}
                <span v-if="item.tagList[0]"> | </span>
                <span>{{ item.tagList[0]?.name }}</span>
              </span>
            </p>
            <!-- <span>{{ item.bookshelfCount ?? 0 }} 人在追</span> -->
          </div>
        </div>
      </router-link>
    </div>

    <div v-if="ending" class="py-15px text-13px text-#999 text-center">
      No more ~
    </div>
    <div v-else-if="fetching" class="py-15px flex items-center justify-center space-x-4 text-13px text-#999">
      <div class="animate-spin">
        <i-icon-park-outline-loading />
      </div>
      <p>Loading...</p>
    </div>
  </div>
</template>

<style scoped>
.cover {
  background: url('@/assets/background-countdown-cover.png');
  background-size: 100% 100%;
}
</style>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { useLoadingStore, useToastStore, useUserStore } from '@/stores'

const { $toast } = useToastStore()
const { $showLoading, $hideLoading } = useLoadingStore()
const userStore = useUserStore()

const router = useRouter()

const signed = ref(false)
const progress = ref<any>([])
const modalSigned = ref(false)
const progressInfo = ref<any>()
const tomorrowInfo = ref<any>()
const checkInDayNum = ref(0)

async function fetchProgress() {
  try {
    const { data } = await api.get<any>('/member/task/signTaskInfo')
    signed.value = data.isSign
    progress.value = data.memberSignResponseList

    // 当前签到信息
    if (signed.value) {
      for (let i = progress.value.length - 1; i >= 0; i--) {
        if (progress.value[i].isSign) {
          progressInfo.value = progress.value[i]
          tomorrowInfo.value = progress.value[i + 1] ?? null
          break
        }
      }
    } else {
      for (let i = 0; i < progress.value.length; i++) {
        if (!progress.value[i].isSign) {
          progressInfo.value = progress.value[i]
          tomorrowInfo.value = progress.value[i + 1] ?? null
          break
        }
      }
    }

    // 领取天数
    for (let i = progress.value.length - 1; i >= 0; i--) {
      if (progress.value[i].isSign) {
        checkInDayNum.value = i + 1
        break
      }
    }

    await userStore.init({ force: true })
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || 'get check in progress failed')
  }
}

async function onCheckIn () {
  if (signed.value)
    return
  try {
    $showLoading()
    await api.get<any>('/member/task/sign')
    modalSigned.value = true
    fetchProgress()
  }
  catch (error: any) {
    console.error(error)
    $toast(error.msg || 'check in failed')
  }
  finally {
    $hideLoading()
  }
}

onMounted(async () => {
  fetchProgress()
})

</script>

<template>
  <div class="min-h-screen pb-10">
    <!-- Cover -->
    <div class="cover w-full h-186px relative">
      <div class="flex justify-between items-center px-5 pt-3.5 pb-3">
        <div>
          <h1 class="text-12px text-#65686F">My Wallet</h1>
        </div>
        <div class="flex items-center">
          <img class="w-24px h-24px" src="@/assets/welfare/welfare-gold.png">
          <strong class="ml-5px text-16px">{{ userStore.$state.coinBalance }}</strong>
          <span class="ml-5px text-12px text-#65686F">coins</span>
        </div>
      </div>
      <div class="mx-10px px-10px py-16px bg-#fff b-rd-2">
        <h3 class="text-18px text-#323435 font-bold">Daily Check-in</h3>
        <p class="text-12px text-#9296A0">You have checked in for {{ checkInDayNum }} day straight!</p>
        <div class="mt-15px px-4px grid grid-cols-7 space-x-8px">
          <div 
            class="text-center" 
            v-for="(item, index) in progress"
            :key="item.num"
            :class="{ 'opacity-70': item.isSign }"
          >
            <div class="flex flex-col items-center pt-10px pb-6px bg-#F6F4FB b-rd-5px">
              <img class="w-24px h-24px" src="@/assets/welfare/welfare-gold.png" v-if="index !== progress.length - 1">
              <img class="w-28px h-25px mb--1px" src="@/assets/welfare/welfare-box.png" v-else>
              <span class="mt-3px text-10px text-#32353A">+{{ item.prizeNum }}</span>
            </div>
            <div class="flex justify-center">
              <i-assets-icon-right class="mt-10px" v-if="item.isSign"/>
              <span class="text-10px text-#9296A0 mt-8px" v-else>Day {{ item.num }}</span>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <a
            class="block mt-4 w-200px h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
            :class="{ 'opacity-70': signed }"
            @click="onCheckIn"
          >
            <span v-if="!signed">Check in</span>
            <span v-else-if="checkInDayNum > 0 && checkInDayNum === progress.length">Tomorrow to Get More</span>
            <span v-else>Tomorrow Check in {{ tomorrowInfo?.prizeNum }}</span>
          </a>
        </div>
      </div>
    </div>

    <BasicModal v-model="modalSigned">
      <div class="p-5">
        <div class="text-17px lh-2em font-bold text-center">
          <p>Check in get <span class="text-#9875FB">{{ progressInfo?.prizeNum }}</span> Coins</p>
        </div>
        <a
          class="block mt-4 w-full h-9 lh-9 text-15px text-white text-center bg-primary rounded-full"
          @click="modalSigned = false"
        >
          Ok
        </a>
      </div>
    </BasicModal>

    <BasicTabbar ref="tabbarRef" tab="rewards" />
  </div>
</template>

<style scoped>
.cover {
  background: url('@/assets/home-bg.png') no-repeat top left;
  background-size: 100% auto;
}
</style>

<script lang="ts" setup>
import { computed, nextTick, onMounted, provide, ref, set, watch } from 'vue'
import { useInterval, useLocalStorage, useScroll, useTitle, watchThrottled } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router/composables'
import { $formatErrorMessage, $loadTextFromCDN } from '@/helpers'
import { clamp } from '@/utils'
import { api } from '@/api'
import { CHAPTER_LOCK, CHARGE_TYPE, ERROR_PAGE_LEVEL, STORAGE_CUSTOM_THEME } from '@/constants'
import { useEffectiveSubmitTrackStore, useReadStore, useToastStore, useUserStore } from '@/stores'
import { useGotoBack } from '@/composables'

const props = defineProps<{
  contentId: string
  chapterId: string
}>()

const route = useRoute()
const router = useRouter()
const { $toast } = useToastStore()
const gotoBack = useGotoBack()
const readStore = useReadStore()
const userStore = useUserStore()
const effectiveSubmitTrackStore = useEffectiveSubmitTrackStore()
const { $unAttribution } = useEffectiveSubmitTrackStore()

const skeleton = ref(true)
const title = useTitle('Loading...')
onMounted(async () => {
  skeleton.value = true
  try {
    await fetchBook()
    await fetchChapterList()
    if (!route.query.skipPosition) {
      // Note: existed different chapterId, void duplicate request chapter
      const existed = await fetchLatestPosition()
      if (existed)
        return
    }
    loadChapter()
  }
  catch (error) {
    $toast($formatErrorMessage(error))
  }
  finally {
    skeleton.value = false
  }
})

const containerRef = ref<HTMLElement>()
provide('containerRef', containerRef)

const book: any = ref<GetContentGetcontentdetailResponse['data']>()
const chapter: any = ref<PostReadPlayResponse['data'] | null>()
const content = ref('')
const paragraphs = computed(() => {
  if (!content.value)
    return []
  return content.value.split(/[\n]/).filter(Boolean)
})
const { reset: resetReadTime } = useInterval(1000, { controls: true })

/**
 * 获取书籍信息
 */
async function fetchBook() {
  const { data } = await api.get<any, GetContentGetcontentdetailResponse>('/content/getContentDetail', { params: { contentId: props.contentId } })
  book.value = data
}

/**
 * 获取章节目录
 */
const chapterList = ref<any>([])
async function fetchChapterList() {
  try {
    const { data: chapterListData } = await api.get<any>('/content/getContentChapterBookList', {
      params: {
        contentId: props.contentId,
        pageIndex: 1,
        pageSize: 1500,
      },
    })
    chapterList.value = chapterListData.records
  }
  catch (error) {
    onErrorHandle()
  }
}

/**
 * 获取章节信息
 */
async function fetchChapter() {
  try {
    const { data } = await api.post<any, PostReadPlayResponse>('/read/play', {
      contentId: props.contentId,
      contentChapterId: props.chapterId,
      sequenceNo: 1,
    })
    const chapterItem = chapterList.value.find((item: any) => item.id === props.chapterId)
    if (chapterItem)
      chapterItem.lockStatus = CHAPTER_LOCK.UNLOCK

    chapter.value.lockStatus = CHAPTER_LOCK.UNLOCK
    content.value = await $loadTextFromCDN(data.bookChapterFileUrl)
    const scrollY = Number(route.query.scrollY ?? 0)
    title.value = chapter.value?.contentChapterName
    const { y } = useScroll(containerRef)
    nextTick(() => {
      y.value = scrollY
    })
    nextTick(() => {
      reportScrollLocation(0)
    })
    // Note: for back last read chapter
    readStore.update({
      contentId: props.contentId,
      chapterId: chapter.value?.contentChapterId as string,
    })
    userStore.init({ force: true })
    await fetchChapterList()
  }
  catch (error: any) {
    const { code } = error
    // Note: 特殊业务处理, 403 为未购买章节
    if (code !== 403)
      onErrorHandle()
  }
  finally {
    resetReadTime()
    skeleton.value = false
  }
}

/**
 * 装载章节
 */
async function loadChapter() {
  // 获取章节信息
  const chapterItem = chapterList.value.find((item: any) => item.id === props.chapterId)
  if (!chapterItem)
    return

  useTitle(chapterItem.chapterName)
  chapter.value = {
    chargeType: book.value?.chargeType, // 单章付费 | 整本付费
    contentChapterName: chapterItem.chapterName,
    priceAmount: book.value?.chargeType === CHARGE_TYPE.CHAPTER ? chapterItem.chapterPrice : book.value?.priceAmount,
    memberCoin: userStore.$state.coinBalance,
    lockStatus: chapterItem.lockStatus,
    bookChapterFileUrl: chapterItem.bookChapterFileUrl,
    bookChapterFreeFileUrl: chapterItem.bookChapterFreeFileUrl,
    prev: chapterItem.preId ? { contentChapterId: chapterItem.preId } : null,
    next: chapterItem.nextId ? { contentChapterId: chapterItem.nextId } : null,
  }
  if (chapterItem.lockStatus !== CHAPTER_LOCK.LOCK) {
    try {
      content.value = await $loadTextFromCDN(chapter.value.bookChapterFileUrl)
      skeleton.value = false
    }
    catch (error) {
      onErrorHandle()
    }
  }
  else if (
    book.value?.chargeType === CHARGE_TYPE.CHAPTER
    && userStore.$state.autoLock
    && chapterItem.chapterPrice < userStore.$state.coinBalance
  ) {
    fetchChapter()
  }
  else if (
    book.value?.chargeType === CHARGE_TYPE.CONTENT
    && userStore.$state.autoLock
    && book.value?.priceAmount < userStore.$state.coinBalance
  ) {
    fetchChapter()
  }
  else if (chapterItem.chapterPrice > userStore.$state.coinBalance) {
    try {
      content.value = await $loadTextFromCDN(chapter.value.bookChapterFreeFileUrl)
      skeleton.value = false
    }
    catch (error) {
      onErrorHandle()
    }
  }
  else if (!userStore.$state.autoLock) {
    try {
      content.value = await $loadTextFromCDN(chapter.value.bookChapterFreeFileUrl)
      skeleton.value = false
    }
    catch (error) {
      onErrorHandle()
    }
  }
  const { y } = useScroll(containerRef)
  nextTick(() => {
    y.value = 0
  })
  nextTick(() => {
    reportScrollLocation(0)
  })
}

watch(() => props.contentId, () => {
  fetchBook()
})
watch(() => props.chapterId, () => {
  skeleton.value = true
  chapter.value = null
  content.value = ''
  loadChapter()
})

/**
 * 上一页
 */
function onPrevChapter() {
  if (!chapter.value!.prev)
    return

  router.replace({ name: 'chapter', params: { contentId: props.contentId, chapterId: chapter.value!.prev.contentChapterId } })
}

/**
 * 下一页
 */
function onNextChapter() {
  attributionTrack()
  if (!chapter.value!.next)
    return
  router.replace({ name: 'chapter', params: { contentId: props.contentId, chapterId: chapter.value!.next.contentChapterId } })
}

/**
 * 获取最后的阅读位置
 */
async function fetchLatestPosition() {
  const { data } = await api.post<any, PostReadPosResponse>('/read/pos', { contentId: book.value!.id })
  router.replace({
    name: 'chapter',
    params: { contentId: book.value!.id, chapterId: data.contentChapterId },
    query: { scrollY: String(data.sectionMediaPlayedSeconds) },
  })
  return props.chapterId !== data.contentChapterId
}

// Report scroll `y` position
async function reportScrollLocation(y: number = 0) {
  try {
    await api.post<any, PostReadReportResponse>('/read/report', {
      contentId: props.contentId,
      chapterId: props.chapterId,
      sectionMediaPlayedSeconds: Math.floor(y),
      videoDuration: containerRef.value ? (containerRef.value.scrollHeight - containerRef.value.clientHeight) : 0,
    })
  }
  catch { } // TODO: Report Error
}

// Popup: TOC(Table of Contents)
const popupTOC = ref(false)
watch(() => route.fullPath, () => {
  popupTOC.value = false
})

/**
 * 打开目录
 */
function onOpenTOC() {
  popupTOC.value = true
  attributionTrack()
}

/**
 * 归因接口, 只有是首次从facebook广告进来, 且点击了目录或者是下一章, 才进行归因
 */
async function attributionTrack() {
  if (!effectiveSubmitTrackStore.$state.isAttribution)
    return

  try {
    const res = await api.post<PostMemberAttributionBody, PostMemberAttributionResponse>('/member/attribution', effectiveSubmitTrackStore.$state.params)
    if (res.code === 0)
      $unAttribution()
    else
      $toast(res.msg || 'Error')
  }
  catch (error: any) {
    $toast(error.msg || 'Error')
  }
}

// 充值弹窗
const popupRecharge = ref(false)
watch(() => route.fullPath, () => {
  popupRecharge.value = false
})
const autoLock = ref(true)
// 充值弹窗
const { y } = useScroll(containerRef)
watchThrottled(
  y,
  async () => {
    !popupRecharge.value && reportScrollLocation(y.value)
  },
  { throttle: 2000, leading: true },
)

watch(() => route.fullPath, () => {
  popupTOC.value = false
})

function onTOCCheckPass() {
  return true
}

// 解锁章节
async function onUnlockChapter() {
  if (!chapter.value)
    return

  if (chapter.value.priceAmount > userStore.$state.coinBalance)
    popupRecharge.value = true

  if (autoLock.value !== userStore.$state.autoLock) {
    await api.post('/member/update/auto-lock', {
      autoLock: autoLock.value,
    })
    await userStore.init({ force: true })
  }
  fetchChapter()
}

function onErrorHandle() {
  router.replace({
    name: 'error',
    query: {
      level: ERROR_PAGE_LEVEL.DEFAULT,
      message: 'Network loading failure',
      redirect: window.encodeURIComponent(window.location.href),
    },
  })
}

// Popup: Theme
// TODO: Move to constants/config?
const THEME_FONT_MAP = ['24', '26', '28', '30', '32', '34', '36', '38', '40', '42', '44', '46', '48', '50']
const THEME_BACKGROUNDS = ['#F6F7F9', '#D5E9D4', '#CEE7FF', '#FFE1DC', '#FBE6B5', '#292929']
const theme = useLocalStorage(STORAGE_CUSTOM_THEME, {
  background: '#F5E9CF',
  size: '42',
})
const THEME_FONT = computed(() => {
  if (!THEME_FONT_MAP.includes(theme.value.size))
    set(theme.value, 'size', '42')

  return {
    chapterTitle: `font-size: ${Number.parseInt(theme.value.size) / 2 + 2}px;font-weight: 500;`,
    chapterContent: `margin-top: 1em;font-size: ${Number.parseInt(theme.value.size) / 2}px;line-height: 1.7em;`,
    paragraphSpacing: 'margin-top: 1em;',
  }
})
provide('theme', theme)

const popupTheme = ref(false)
watch(() => route.fullPath, () => {
  popupTheme.value = false
})
function onToggleTheme() {
  popupTOC.value = false
  popupTheme.value = !popupTheme.value
}
function onChangeThemeSize(zoom: number) {
  const index = clamp(THEME_FONT_MAP.indexOf(theme.value.size) + zoom, 0, THEME_FONT_MAP.length - 1)
  set(theme.value, 'size', THEME_FONT_MAP[index])
}
</script>

<template>
  <div v-if="skeleton" class="min-h-screen" :style="`background: ${theme.background}`">
    <div class="pt-20vh flex justify-center">
      <BasicSpinner class="mx-auto" />
    </div>
    <p class="mt-4 text-center font-bold">
      Loading...
    </p>
  </div>
  <div v-else>
    <div ref="containerRef" :class="`h-screen overflow-scroll color-${theme.background.replace('#', '')}`" :style="`background: ${theme.background}`">
      <!-- Note: Custom  -->
      <BasicNavbar v-show="y === 0 || !popupTheme" scale :title="chapter?.contentChapterName" />
      <transition name="slide">
        <div
          v-if="popupTheme && y !== 0" class="w-full fixed top-0 flex items-center z-10"
          :style="`background: ${theme.background}`"
        >
          <div class="p-2 pl-4" @click="gotoBack()">
            <i-icon-park-outline-left class="w-6 h-6" />
          </div>
        </div>
      </transition>

      <!-- Container -->
      <div v-if="chapter" class="px-20px py-10 min-h-screen" @click="onToggleTheme">
        <p :style="THEME_FONT.chapterTitle">
          {{ chapter?.contentChapterName }}
        </p>
        <div :style="THEME_FONT.chapterContent">
          <p v-for="(item, index) in paragraphs" :key="index" :style="THEME_FONT.paragraphSpacing">
            {{ item }}
          </p>
        </div>
        <div v-if="chapter?.lockStatus === CHAPTER_LOCK.LOCK" class="pt-40px chapter-payment text-center">
          <h3 class="theme-h3 font-bold text-18px text-center text-#32353A">
            Unlock Now
          </h3>
          <p class="theme-desc mt-8px lh-24px text-14px text-#65686F text-center">
            Show your support to inspire the writer to come<br>upwith more fantastic stories
          </p>
          <a
            class="theme-btn inline-block mt-24px px-24px h-40px lh-40px text-15px text-white text-center bg-primary rounded-full whitespace-normal"
            @click.stop="onUnlockChapter"
          >
            <span v-if="chapter.chargeType === CHARGE_TYPE.CHAPTER">{{ chapter.priceAmount }} coins to read this episode</span>
            <span v-else>{{ chapter.priceAmount }} coins to read the whole book</span>
          </a>
          <div class="theme-radio mt-10px flex justify-center items-center" @click.stop="autoLock = !autoLock">
            <div class="radio flex justify-center items-center w-14px h-14px inline-block border b-#9296A0 b-rd-14px cursor" :class="{ selected: autoLock }">
              <span class="inline-block w-6px h-6px bg-#9875FB b-rd-6px" />
            </div>
            <span class="ml-6px label text-14px text-#65686F">Auto unlock</span>
          </div>
          <p class="theme-balance mt-24px pb-18px lh-24px text-14px text-#9296A0 text-center">
            Coins Balance: {{ chapter.memberCoin }} Coins
          </p>
          <div class="mask" />
        </div>
        <div class="mt-5 flex items-center justify-between">
          <span
            class="theme-btn-bg w-105px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
            :class="{ 'text-#999': !chapter.prev }" @click.stop="onPrevChapter"
          >
            Prev
          </span>
          <span class="theme-btn-bg w-100px h-39px lh-39px text-center text-14px bg-#0000000A rounded" @click.stop="popupTOC = true">
            Catalogue
          </span>
          <span
            class="theme-btn-bg w-105px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
            :class="{ 'text-#999': !chapter.next }"
            @click.stop="onNextChapter"
          >
            Next
          </span>
        </div>
      </div>

      <!-- Popup: 设置主题 -->
      <BasicPopup v-model="popupTheme" :mask="false">
        <div class="w-full px-5 pt-25px py-10" :style="`background: ${theme.background}`">
          <div class="mb-5 flex space-x-25px items-center">
            <p class="flex-1 min-w-8 text-14px text-#999">
              Size
            </p>
            <div class="flex-1 flex items-center justify-between">
              <span
                class="w-90px h-35px lh-35px text-center text-14px bg-#0000000A b-rd-tl-18px b-rd-bl-18px"
                :class="{ 'text-#999': theme.size === '24' }" @click="onChangeThemeSize(-1)"
              >
                A-
              </span>
              <span class="flex justify-between items-center w-90px h-35px lh-35px min-w-5 text-15px text-center bg-#0000000A">
                <span class="text-#0000001A">|</span>
                <span>{{ theme.size }}</span>
                <span class="text-#0000001A">|</span>
              </span>
              <span
                class="w-90px h-35px lh-35px text-center text-14px bg-#0000000A b-rd-tr-18px b-rd-br-18px"
                :class="{ 'text-#999': theme.size === '50' }" @click="onChangeThemeSize(1)"
              >
                A+
              </span>
            </div>
          </div>
          <div class="flex space-x-25px items-center">
            <div class="flex flex-1 justify-center space-x-20px">
              <span
                v-for="item in THEME_BACKGROUNDS" :key="item" class="w-30px h-30px border rounded-full"
                :style="`background: ${item};`"
                :class="item === theme.background ? 'border-2 border-primary' : 'border-#ccc'"
                @click="theme.background = item"
              />
            </div>
          </div>
          <div class="mt-5 flex items-center justify-between">
            <span
              class="theme-btn-bg w-115px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
              :class="{ 'text-#999': !chapter?.prev }" @click="onPrevChapter"
            >
              Prev</span>
            <span
              class="theme-btn-bg w-80px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
              @click.stop="onOpenTOC"
            >
              Catalogue
            </span>
            <span
              class="theme-btn-bg w-115px h-39px lh-39px text-center text-14px bg-#0000000A rounded"
              @click="onNextChapter"
            >
              Next
            </span>
          </div>
        </div>
      </BasicPopup>
      <BizPopupBookTOC
        v-model="popupTOC" :book="book" :content-id="props.contentId" :chapter-id="props.chapterId"
        :check-pass-goto-chapter="(index) => onTOCCheckPass()"
      />
      <!-- Popup: 充值列表 -->
      <BasicPopup v-model="popupRecharge">
        <div class="popupRecharge w-full px-5 pt-20px pb-30px bg-white rounded-t-lg relative">
          <h3 class="text-18px text-center">
            Chapter is locked
          </h3>
          <p class="text-14px text-#65686F text-center">
            Choose a recharge level and unlock chapters
          </p>
          <div class="recharge-list">
            <BizPayTemplate :type="1" :content-id="props.contentId" :content-chapter-id="props.chapterId" />
          </div>
        </div>
      </BasicPopup>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.recharge-list {
  max-height: 70vh;
  overflow-y: auto;
}
.popupRecharge {
  background: #fff;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.slide-enter-active {
  transition: all 0.2s ease;
  transform: translateY(0%);
}

.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-100%);
}

.add-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.add-text {
  color: #666;
}

.add-btn {
  color: #ff9928;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-btn-text {
  margin-right: 6px;
  font-weight: bold;
}

.recharge-interception {
  background: url("@/assets/bg-recharge-interception.png");
  background-size: 100% 100%;
  height: 30px;
  padding-left: 25px;
}

:deep(.countdown .bg-primary) {
  background: #FF6E0F66;
}

.radio {
  span {
    display: none;
  }
  &.selected {
    border-color: #9875FB;
    span {
      display: inline-block
    }
  }
}
.chapter-payment {
  position: relative;
  .mask {
    position: absolute;
    top: -126px;
    left: 0;
    width: 100%;
    height: 126px;
  }
}
.color-F6F7F9 {
  .mask {
    background-image: linear-gradient(180deg, rgba(246,247,249,0.00) 20%, #F6F7F9 100%);
  }
}
.color-D5E9D4 {
  .mask {
    background-image: linear-gradient(180deg, rgba(213,233,212,0.00) 20%, #D5E9D4 100%);
  }
  .theme-h3 {
    color: #4F5E4E;
  }
  .theme-desc {
    color: #4F5E4E;
  }
  .theme-btn {
    background-color: #577953;
  }
  .theme-radio {
    color: #4F5E4E;
    .radio.selected {
      border-color: #577953;
      span {
        background-color: #577953;
      }
    }
  }
  .theme-balance {
    color: #87A184;
  }
}
.color-CEE7FF {
  .mask {
    background-image: linear-gradient(180deg, rgba(206,231,255,0.00) 20%, #CEE7FF 100%);
  }
  .theme-h3 {
    color: #52648A;
  }
  .theme-desc {
    color: #52648A;
  }
  .theme-btn {
    background-color: #607BB3;
  }
  .theme-radio {
    color: #52648A;
    .radio.selected {
      border-color: #607BB3;
      span {
        background-color: #607BB3;
      }
    }
  }
  .theme-balance {
    color: #8DA1CA;
  }
}
.color-FFE1DC {
  .mask {
    background-image: linear-gradient(180deg, rgba(255,225,220,0.00) 20%, #FFE1DC 100%);
  }
  .theme-h3 {
    color: #814156;
  }
  .theme-desc {
    color: #814156;
  }
  .theme-btn {
    background-color: #A44665;
  }
  .theme-radio {
    color: #814156;
    .radio.selected {
      border-color: #A44665;
      span {
        background-color: #A44665;
      }
    }
  }
  .theme-balance {
    color: #BF7B90;
  }
}
.color-FBE6B5 {
  .mask {
    background-image: linear-gradient(180deg, rgba(251,230,181,0.00) 20%, #FBE6B5 100%);
  }
  .theme-h3 {
    color: #6A482E;
  }
  .theme-desc {
    color: #6A482E;
  }
  .theme-btn {
    background-color: #C16F30;
  }
  .theme-radio {
    color: #6A482E;
    .radio.selected {
      border-color: #C16F30;
      span {
        background-color: #C16F30;
      }
    }
  }
  .theme-balance {
    color: #D1986C;
  }
}
.color-292929 {
  * {
    color: #aaa;
  }
  .mask {
    background-image: linear-gradient(180deg, rgba(41,41,41,0.00) 20%, #292929 100%);
  }
  .theme-h3 {
    color: #aaa;
  }
  .theme-desc {
    color: #aaa;
  }
  .theme-btn {
    span {
      color: #fff;
    }
  }
  .theme-radio {
    color: #aaa;
    .radio.selected {
      border-color: #aaa;
      span {
        background-color: #aaa;
      }
    }
  }
  .theme-balance {
    color: #636363;
  }
  .theme-btn-bg {
    background: #313030;
  }
}
</style>

<script lang="ts" setup>
import { useRoute } from 'vue-router/composables'
import { track } from '@/track'

const props = defineProps<{
  name: string
  type: 'tab' | 'link' | 'button' | 'image'
  extra?: Record<string, any>
}>()

const route = useRoute()
function onClick() {
  // todo 埋点上报
  // track('elementClick', {
  //   element_name: props.name,
  //   element_type: props.type,
  //   current_page: route.fullPath,
  //   current_page_title: route.meta?.title ?? '',
  //   ...props.extra,
  // })
}
</script>

<template>
  <div @click.capture="onClick">
    <slot />
  </div>
</template>
